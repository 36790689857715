<template>
  <fmv-sidebar ref="sidebar" :type="sidebarType" :variant="sidebarVariant" @update="scrollIntoView">
    <!-- Search -->
    <div v-if="sidebarSearch" class="d-flex align-items-center navbar-height">
      <b-form class="search-form w-100 mx-16pt pr-0 pl-16pt" :class="sidebarSearchFormClass">
        <b-form-input :placeholder="$t('Search')" type="text" class="pl-0" />
        <b-button variant="none">
          <md-icon>search</md-icon>
        </b-button>
      </b-form>
    </div>
    <!-- // END Search -->

    <span v-if="sidebarBrand" class="sidebar-brand" :class="sidebarBrandClass">
      <b-img :src="brandImage" width="40" class="mb-3" alt="logo" />
      <span v-text="sidebarBrand" />
    </span>

    <slot v-bind:sidebar="{ menu }">
      <template v-if="sidebarMenu">
        <!-- Sidebar routes -->

        <template v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN">
          <!-- <div class="sidebar-heading"></div> -->
          <sidebar-menu :menu="menu.superAdmin" class="mb-24pt" />
        </template>

        <template v-else-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL">
          <!-- <div class="sidebar-heading"></div> -->
          <ul class="sidebar-menu mb-0" v-if="get(getLinkedEntity, 'subdomain.subdomain_url')">
            <li class="sidebar-menu-item">
              <a :href="get(getLinkedEntity, 'subdomain.subdomain_url')" class="sidebar-menu-button" target="_blank"
                ><i class="material-icons sidebar-menu-icon sidebar-menu-icon--left">web</i>
                <span class="sidebar-menu-text">Enrollment Site</span>
              </a>
            </li>
          </ul>
          <sidebar-menu :menu="menu.school" class="mb-24pt" ref="schoolSideMenu" :key="key" />
        </template>

        <template v-else-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT">
          <!-- <div class="sidebar-heading"></div> -->
          <sidebar-menu :menu="menu.student" class="mb-24pt mt-4" />
        </template>

        <template v-else>
          <div class="mt-5 px-3">
            <div class="text-light text-break" v-if="get(getCurrentSchool, 'custom_navbars.length')">
              <h5 class="text-light">Menu</h5>
              <div class="mb-1" v-for="item in get(getCurrentSchool, 'custom_navbars')" :key="item.id">
                <a style="color: white" :href="getClickableLink(item.link)" target="_blank">
                  <md-icon class="ml-0 mr-2">{{ item.icon }}</md-icon>
                  {{ item.text }}
                </a>
              </div>
            </div>
          </div>
          <!-- School's website link for public pages. -->
          <!-- <ul class="sidebar-menu mb-0 mt-4" v-if="get(getCurrentSchool, 'website_url')">
            <li class="sidebar-menu-item">
              <a
                :href="getClickableLink(get(getCurrentSchool, 'website_url'))"
                class="sidebar-menu-button"
                target="_blank"
                ><i class="material-icons sidebar-menu-icon sidebar-menu-icon--left">home</i>
                <span class="sidebar-menu-text">Home</span>
              </a>
            </li>
          </ul>

          <sidebar-menu :menu="menu.unauthRoutes" class="mb-24pt" /> -->
        </template>

        <!-- <template>
          <div class="sidebar-heading"></div>
          <sidebar-menu :menu="i18nMenu.student" class="mb-24pt" />
        </template>

        <template v-if="menu.components && menu.components.length">
          <div class="sidebar-heading">{{ $t('Components') }}</div>
          <sidebar-menu :menu="i18nMenu.components" class="mb-24pt" />
        </template> -->
      </template>
    </slot>
  </fmv-sidebar>
</template>

<script>
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { FmvSidebar } from 'fmv-layout';
import routeToMixin from '@/mixins/route-to';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { mapGetters } from 'vuex';
import { get } from 'lodash';

import { USER_ROLES, USER_ROLE_TYPES, SCHOOL_PAYMENT_PLANS, SCHOOL_TYPES } from '../../common/constants';

import brandImage from '@/assets/images/logos/logo_icon.png';
import SidebarMenu from './SidebarMenu.vue';

export default {
  components: {
    FmvSidebar,
    SidebarMenu,
    MdIcon,
  },
  mixins: [routeToMixin],
  props: {
    sidebarType: {
      type: String,
      default: () => 'dark-pickled-bluewood',
    },
    sidebarVariant: {
      type: String,
      default: () => null,
    },
    sidebarBrand: {
      type: [String, Boolean],
      default: () => 'Brand',
    },
    brandImage: {
      type: String,
      default() {
        return brandImage;
      },
    },
    sidebarBrandClass: {
      type: [Array, String, Object],
      default: null,
    },
    sidebarSearch: {
      type: Boolean,
      default: true,
    },
    sidebarSearchFormClass: {
      type: [Array, String, Object],
      default: () => null,
    },
    sidebarMenu: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      USER_ROLES,
      USER_ROLE_TYPES,
      SCHOOL_PAYMENT_PLANS,
      SCHOOL_TYPES,
      scrollIntoViewElement: null,
      key: 'render',
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool', 'getSchoolPaymentPlan']),
    ...mapGetters('navbarConfig', ['getLinkedEntity']),

    menu() {
      return {
        default: [
          {
            label: this.$t('home'),
            route: { name: 'home' },
            icon: { type: 'md-icon', id: 'home' },
          },
        ],
        superAdmin: [
          {
            label: this.$t('home'),
            route: { name: 'admin-dashboard' },
            icon: { type: 'md-icon', id: 'home' },
          },
          {
            label: 'Users',
            route: { name: 'manage-users' },
            icon: { type: 'md-icon', id: 'person' },
          },
          {
            label: 'Payments',
            route: { name: 'manage-payouts' },
            icon: { type: 'md-icon', id: 'payment' },
          },
          {
            label: 'Refund Requests',
            route: { name: 'manage-req-refunds' },
            icon: { type: 'md-icon', id: 'attach_money' },
          },
          // {
          //   label: 'Email Templates',
          //   route: { name: 'sa-manage-system-emails' },
          //   icon: { type: 'md-icon', id: 'email' },
          // },
          {
            label: 'Schools',
            icon: { type: 'md-icon', id: 'account_balance' },
            id: 'schools',
            children: [
              {
                label: 'Schools Accounts',
                route: { name: 'manage-schools' },
                icon: { type: 'md-icon', id: 'account_balance' },
              },
              {
                label: 'Interested Schools',
                route: { name: 'manage-int-schools' },
                icon: { type: 'md-icon', id: 'business' },

                // exact: true,
              },
            ],
          },
          {
            label: 'Website Config',
            icon: { type: 'md-icon', id: 'settings' },
            id: 'website-config',
            children: [
              {
                label: 'Basic',
                route: { name: 'basic-config' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Home',
                route: { name: 'homepage-config' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'About',
                route: { name: 'about-page' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Tuition & Financing',
                route: { name: 'tuition-page' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Locations',
                route: { name: 'campus-page' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Gallery',
                route: { name: 'gallery-page' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'FAQs',
                route: { name: 'faqs' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Resources',
                route: { name: 'manage-resources' },
                icon: { type: 'md-icon', id: 'settings' },
              },
              {
                label: 'Blogs',
                route: { name: 'sa-manage-blogs' },
                icon: { type: 'md-icon', id: 'vignette' },
              },
              {
                label: 'Custom Pages',
                route: { name: 'manage-custom-pages' },
                icon: { type: 'md-icon', id: 'settings' },
              },

              // {
              //   label: 'Interested Schools',
              //   route: { name: 'manage-int-schools' },
              //   icon: { type: 'md-icon', id: 'business' },

              //   // exact: true,
              // },
            ],
          },
          // {
          //   label: this.$t('schoolMsgs.schools'),
          //   route: { name: 'manage-schools' },
          //   icon: { type: 'md-icon', id: 'account_balance' },
          // },
          // {
          //   label: this.$t('studentMsgs.students'),
          //   route: { name: 'manage-students' },
          //   icon: { type: 'md-icon', id: 'school' },
          // },
          {
            label: 'Programs',
            route: { name: 'sa-manage-programs' },
            icon: { type: 'md-icon', id: 'local_library' },
          },
          // {
          //   label: 'Interested Schools',
          //   route: { name: 'manage-int-schools' },
          //   icon: { type: 'md-icon', id: 'business' },
          // },
          // {
          //   label: 'Classes',
          //   route: { name: 'classes-management-list' },
          //   icon: { type: 'md-icon', id: 'class' },
          // },
          {
            label: 'Best School Location',
            icon: { type: 'md-icon', id: 'location_on' },
            id: 'location',
            children: [
              {
                label: 'States',
                route: { name: 'sa-manage-states' },
                exact: true,
              },
              {
                label: 'Cities',
                route: { name: 'sa-manage-cities' },
                exact: true,
              },
            ],
          },
          {
            label: 'Templates',
            route: { name: 'manage-templates' },
            icon: { type: 'md-icon', id: 'collections' },
          },
        ],

        school: [
          {
            label: 'Home',
            route: { name: 'school-dashboard' },
            icon: { type: 'md-icon', id: 'home' },
          },
          {
            label: 'Programs',
            route: { name: 'manage-programs' },
            icon: { type: 'md-icon', id: 'local_library' },
          },
          // {
          //   label: 'Enrollment Analytics',
          //   route: { name: 'school-enrollment-report' },
          //   icon: { type: 'md-icon', id: 'insert_drive_file' },
          // },
          {
            label: 'Classes',
            id: 'classes',
            icon: { type: 'md-icon', id: 'class' },
            children: [
              {
                label: 'Locations',
                route: { name: 'class-location-list' },
              },
              {
                label: 'Listing',
                route: { name: 'classes-management-list' },
              },
            ],
          },

          {
            label: 'Instructors',
            route: { name: 'instructor-management-list' },
            icon: { type: 'md-icon', id: 'people_alt' },
          },
          {
            label: 'Students',
            id: 'students',
            icon: { type: 'md-icon', id: 'school' },
            children: [
              {
                label: 'Registered Students',
                route: { name: 'schl-students-list' },
              },
              {
                label: 'Pre-enrollments',
                route: { name: 'schl-pre-enroll-students-list' },
                exact: true,
              },
              {
                label: 'Initiated Registrations',
                route: { name: 'initiated-registrations' },
              },
            ],
          },
          {
            label: 'Products',
            icon: { type: 'md-icon', id: 'local_mall' },
            id: 'products',
            children: [
              {
                label: 'Inventory',
                route: { name: 'manage-product-list' },
              },
              {
                label: 'Purchases',
                route: { name: 'manage-purchase-list' },
                exact: true,
              },
            ],
          },

          {
            label: 'Branding',
            icon: { type: 'md-icon', id: 'tune' },
            id: 'branding',
            children: [
              ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER
                ? [
                    {
                      label: 'School Profile',
                      route: { name: 'manage-school-profile' },
                      exact: true,
                    },
                  ]
                : []),
              {
                label: 'Enrollment Questions',
                route: { name: 'manage-questions' },
              },
              {
                label: 'Email Templates',
                route: { name: 'email-management-list' },
              },
              {
                label: 'Inquiry Widget',
                route: { name: 'manage-inquiry-widget' },
                exact: true,
              },
              {
                label: 'Custom Navbar Menu',
                route: { name: 'custom-navbar-menu' },
                exact: true,
              },
            ],
          },
          {
            label: 'Payments',
            icon: { type: 'md-icon', id: 'payment' },
            id: 'payment',
            children: [
              ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER
                ? [
                    {
                      label: 'Overview',
                      route: { name: 'manage-payment' },
                      icon: { type: 'md-icon', id: 'payment' },
                    },
                  ]
                : []),

              {
                label: 'Refund Requests',
                route: { name: 'req-refunds' },
                icon: { type: 'md-icon', id: 'attach_money' },
              },
            ],
          },

          ...(this.getSchoolPaymentPlan &&
          (this.getSchoolPaymentPlan.payment_plan === this.SCHOOL_PAYMENT_PLANS.ADVANCED ||
            this.getSchoolPaymentPlan.payment_plan === this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT)
            ? [
                {
                  label: 'Website Config',
                  icon: { type: 'md-icon', id: 'settings' },
                  id: 'website-config',
                  children: [
                    {
                      label: 'Basic',
                      route: { name: 'basic-config' },
                      icon: { type: 'md-icon', id: 'settings' },
                    },
                    {
                      label: 'Home',
                      route: { name: 'homepage-config' },
                      icon: { type: 'md-icon', id: 'settings' },
                    },
                    {
                      label: 'About',
                      route: { name: 'about-page' },
                      icon: { type: 'md-icon', id: 'settings' },
                    },
                    {
                      label: 'Tuition & Financing',
                      route: { name: 'tuition-page' },
                      icon: { type: 'md-icon', id: 'settings' },
                    },
                    {
                      label: 'Locations',
                      route: { name: 'campus-page' },
                      icon: { type: 'md-icon', id: 'settings' },
                    },
                    {
                      label: 'Gallery',
                      route: { name: 'gallery-page' },
                      icon: { type: 'md-icon', id: 'settings' },
                    },
                    {
                      label: 'FAQs',
                      route: { name: 'faqs' },
                      icon: { type: 'md-icon', id: 'settings' },
                    },
                    {
                      label: 'Resources',
                      route: { name: 'manage-resources' },
                      icon: { type: 'md-icon', id: 'settings' },
                    },
                    {
                      label: 'Blogs',
                      route: { name: 'sa-manage-blogs' },
                      icon: { type: 'md-icon', id: 'vignette' },
                    },
                    {
                      label: 'Custom Pages',
                      route: { name: 'manage-custom-pages' },
                      icon: { type: 'md-icon', id: 'settings' },
                    },
                  ],
                },
              ]
            : []),

          ...(this.getSchoolPaymentPlan &&
          (this.getSchoolPaymentPlan.payment_plan === this.SCHOOL_PAYMENT_PLANS.BASIC_AND_CHAT ||
            this.getSchoolPaymentPlan.payment_plan === this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT)
            ? [
                {
                  label: 'AI Assistants',
                  icon: { type: 'md-icon', id: 'bolt' },
                  id: 'my_ai',
                  children: [
                    {
                      label: 'Chat',
                      route: { name: 'chat-bot' },
                      exact: true,
                    },
                    {
                      label: 'Marketing',
                      route: { name: 'marketing' },
                      exact: true,
                    },
                  ],
                },
              ]
            : []),

          ...(get(this.getLoggedInUser, 'linked_entity.school_type') === SCHOOL_TYPES.LOCAL
            ? [
                {
                  label: 'Training Partners',
                  icon: { type: 'md-icon', id: 'account_balance' },
                  id: 'national-partners',
                  children: [
                    {
                      label: 'Listings',
                      route: { name: 'national-partners-list' },
                    },
                    {
                      label: 'Affiliations',
                      route: { name: 'manage-affiliations-list' },
                      exact: true,
                    },
                    {
                      label: 'Programs',
                      route: { name: 'national-partners-programs' },
                      exact: true,
                    },
                  ],
                },
              ]
            : [
                {
                  label: 'Affiliations',
                  icon: { type: 'md-icon', id: 'account_balance' },
                  route: { name: 'manage-affiliations-list' },
                },
              ]),
        ],

        student: [
          // {
          //   label: 'Classes',
          //   route: { name: 'std-manage-resume' },
          //   icon: { type: 'md-icon', id: 'speaker_notes' },
          // },
        ],
        unauthRoutes: [
          {
            label: 'Programs',
            route: { name: 'sch-public-home' },
            icon: { type: 'md-icon', id: 'school' },
            exact: true,
          },
        ],
      };
    },
  },
  watch: {
    scrollIntoViewElement: 'scrollIntoView',
    getSchoolPaymentPlan: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'payment_plan') && this.$refs['schoolSideMenu']) {
          this.key = 'payment_plan_render';
          this.$refs['schoolSideMenu'].$forceUpdate();
        }
      },
    },
  },
  created() {
    this.$root.$on('fmv::sidebar-menu::state', ({ targetId, opened }) => {
      this.updateSidebarScroll();
      if (opened) {
        this.scrollIntoViewElement = targetId;
      }
    });
  },
  methods: {
    get,

    updateSidebarScroll() {
      this.$nextTick(() => {
        if (this.$refs.sidebar) {
          this.$refs.sidebar.update();
        }
      });
    },
    scrollIntoView() {
      this.$nextTick(() => {
        const target = document.querySelector(`#${this.scrollIntoViewElement}`);
        if (target) {
          elementScrollIntoView(target, {
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }
      });
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    },
  },
};
</script>
